document.addEventListener('DOMContentLoaded', function () {
	var sliderClass = document.querySelectorAll('.hero-slider .splide')
	if (sliderClass.length > 0) {
		for (var i = 0; i < sliderClass.length; i++) {
			new Splide(sliderClass[i], {
				type: 'fade',
				speed: 1000,
				autoplay: true,
				interval: 4000,
				lazyload: false,
				pagination: false,
				rewind: true,
				rewindSpeed: 1,
				autoHeight: true,
				autoWidth: true,
				cover: true,
				arrows: false,
				easing: 'linear',
			}).mount()
		}
	}
})
