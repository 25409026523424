document.addEventListener('DOMContentLoaded', function () {
	var sliderClass = document.querySelectorAll('.classic-slider .splide')
	if (sliderClass.length > 0) {
		for (var i = 0; i < sliderClass.length; i++) {
			new Splide(sliderClass[i], {
				type: 'loop',
				speed: 1000,
				autoplay: true,
				interval: 4000,
				lazyload: false,
			}).mount()
		}
	}
})
