document.addEventListener('DOMContentLoaded', function () {
	var popupsTrigger = document.querySelectorAll('.popup-trigger, .popup-close')
	if (popupsTrigger.length) {
		popupsTrigger.forEach(function (popupTrigger) {
			popupTrigger.addEventListener(
				'click',
				function (event) {
					event.preventDefault()
					var popup = document.querySelector('.' + popupTrigger.dataset.popup)
					popup.classList.toggle('open')
					if ('popup-newsletter' == popupTrigger.dataset.popup) {
						Cookies.set('popup-newsletter', 'true', { expires: 2 })
					}
				},
				false
			)
		})
	}

	var cookie = Cookies.get('popup-newsletter')
	var popupsNewsletter = document.querySelectorAll(
		'.site-popup.popup-newsletter'
	)

	if (popupsNewsletter.length) {
		if (cookie === 'true') {
			popupsNewsletter.forEach(function (popupsNews) {
				popupsNews.classList.remove('open')
			})
		} else {
			popupsNewsletter.forEach(function (popupsNews) {
				popupsNews.classList.add('open')
			})
		}
	}
})
