/**
 * Scroll Utils
 */

/**
 * Add identifiers for first and last block
 */
const scrollUtils = () => {
	let lastScrollTop = 0
	let didScroll

	document.body.classList.add('scroll-up', 'scroll-top')

	// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
	window.addEventListener(
		'scroll',
		function () {
			didScroll = true
		},
		false
	)
	setInterval(function () {
		if (didScroll) {
			var st = window.pageYOffset || document.documentElement.scrollTop
			if (st > lastScrollTop) {
				// downscroll code
				document.body.classList.remove('scroll-down', 'scroll-up')
				document.body.classList.add('scroll-down')
			} else {
				// upscroll code
				document.body.classList.remove('scroll-down', 'scroll-up')
				document.body.classList.add('scroll-up')
			}
			if (st >= 0 && st < 5) {
				// scroll top code
				// document.body.classList.remove('scroll-top')
				document.body.classList.add('scroll-top')
			} else {
				document.body.classList.remove('scroll-top')
			}
			lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
			didScroll = false
		}
	}, 250)
}
scrollUtils()
