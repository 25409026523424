;(function (document, history, location) {
	var HISTORY_SUPPORT = !!(history && history.pushState)

	var anchorScrolls = {
		ANCHOR_REGEX: /^#[^ ]+$/,
		OFFSET_HEIGHT_PX: 50,

		/**
		 * Establish events, and fix initial scroll position if a hash is provided.
		 */
		init: function () {
			this.scrollToCurrent()
			jQuery(window).on('hashchange', jQuery.proxy(this, 'scrollToCurrent'))
			jQuery('body').on('click', 'a', jQuery.proxy(this, 'delegateAnchors'))
		},

		/**
		 * Return the offset amount to deduct from the normal scroll position.
		 * Modify as appropriate to allow for dynamic calculations
		 */
		getFixedOffset: function () {
			if (jQuery('#masthead').length) {
				var temp_offset = parseInt(jQuery('#masthead').outerHeight(true), 10)
				this.OFFSET_HEIGHT_PX = temp_offset
			}
			return this.OFFSET_HEIGHT_PX
		},

		/**
		 * If the provided href is an anchor which resolves to an element on the
		 * page, scroll to it.
		 * @param  {String} href
		 * @return {Boolean} - Was the href an anchor.
		 */
		scrollIfAnchor: function (href, pushToHistory) {
			var match, anchorOffset

			if (!this.ANCHOR_REGEX.test(href)) {
				return false
			}

			match = document.getElementById(href.slice(1))

			if (match) {
				anchorOffset = jQuery(match).offset().top - this.getFixedOffset()
				jQuery('html, body').animate({ scrollTop: anchorOffset })

				// Add the state to history as-per normal anchor links
				if (HISTORY_SUPPORT && pushToHistory) {
					history.pushState({}, document.title, location.pathname + href)
				}
			}

			return !!match
		},

		/**
		 * Attempt to scroll to the current location's hash.
		 */
		scrollToCurrent: function (e) {
			if (this.scrollIfAnchor(window.location.hash) && e) {
				e.preventDefault()
			}
		},

		/**
		 * If the click event's target was an anchor, fix the scroll position.
		 */
		delegateAnchors: function (e) {
			var elem = e.target

			if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
				e.preventDefault()
			}
		},
	}

	jQuery(document).ready(jQuery.proxy(anchorScrolls, 'init'))
})(window.document, window.history, window.location)
